.fc-unthemed {
  & .fc-past, & .fc-other-month {
    background: #f9f9f9;
  }
  & .fc-all-slots-free {
    background: #5cb85c;
    color: #fff;
  }
  & .fc-some-slots-free {
    background: #eea236;
    color: #fff;
  }
  & .fc-no-slots {
    background: #d43f3a;
    color: #fff;
  }
}

.m-b-30 {
  margin-bottom: 30px;
}

.popover {
  max-width: 450px !important;
}

// .btn-cancel-booking {
//   margin-top: 10px;
// }

#agenda {
  width: 100%;

  ul {
    list-style: none;

    li {
      padding: 10px 0;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-bottom: -5px;
        margin-right: 10px;
      }
    }
  }
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto !important;
}

.table {
  &.slot-details {
    visibility: hidden;
    margin: 0;
    display: none;

    @media print {
      display: table;
      visibility: visible;
      width: 100%;
      border: none;
    }

    td {
      border: none;
      border-right: 1px solid #ddd;

      &:last-child {
        border: none;
      }
    }
  }
}

input[name=obstetrics] {
  margin-top: 10px;
}

@media print {
  body {
    padding: 0;
  }
}
